<template>
<div class="containerInline" >

  <div class="row">
    <div class="col-lg-6 col-sm-12"><h4>Profildaten</h4>
      <div class="customerData">

          <div class="row">
            <div class="xlarge-4 xlarge-push-8 large-12 medium-12 small-12 columns" >
              <p style="text-align: center" v-if="customer.profile_image">
              <img  :src="'data:'+customer.profile_mimetype+';base64,'+customer.profile_image" class="profileImage" />
                <button class="btn btn-outline-danger mt-2 " @click="deleteAvatar()"><i class="fa fa-duotone fa-trash"></i> Bild entfernen</button>
              </p>

            </div>
            <div class="xlarge-8 xlarge-pull-4 large-12 medium-12 small-12 columns">
              <div class="table-responsive">
              <table class="profileData">
                <tr>
                  <td style="width: 170px;" class="align-top">Name</td>
                  <td class="align-top">{{customer.c_firstname}} {{customer.c_lastname}}</td>
                </tr>
                <tr>
                  <td class="align-top d-none d-lg-block ">Anschrift</td>
                  <td class="align-top">{{customer.c_street}}<br>{{customer.c_zipcode}} {{customer.c_city}}</td>
                </tr>
                <tr>
                  <td class="align-top d-none d-lg-block ">Geburtsdatum</td>
                  <td class="align-top">{{$filters.formatDate(customer.c_birthday)}}</td>
                </tr>
                <tr>
                  <td class="align-top d-none d-lg-block ">Geburtsort</td>
                  <td class="align-top">{{customer.birthday_city}}</td>
                </tr>
                <tr>
                  <td class="align-top d-none d-lg-block ">Email</td>
                  <td class="align-top"> {{customer.c_email}}</td>
                </tr>
                <tr>
                  <td class="align-top d-none d-lg-block ">Handynummer</td>
                  <td class="align-top"> {{customer.c_handy}}</td>
                </tr>
              </table>
            </div>
          </div>



    </div>
      </div>

      <div class="customerData mt-4" v-if="customer.custom_fields">
        <h4>Aktuelle Trainerstation</h4>
        <div class="table-responsive">
          <table class="w100">
            <tr>
              <td style="width: 170px" class="align-top">Mannschaft</td>
              <td class="align-top">{{customer.custom_fields.mannschaft}}</td>
            </tr>
            <tr>
              <td class="align-top d-none d-lg-block ">Liga</td>
              <td class="align-top">{{customer.custom_fields.liga}}</td>
            </tr>
            <tr>
              <td class="align-top d-none d-lg-block ">Altersbereich</td>
              <td class="align-top">{{customer.custom_fields.altersbereich}}</td>
            </tr> <tr>
              <td class="align-top d-none d-lg-block ">Aufstieg</td>
              <td class="align-top"><template v-if="customer.custom_fields.aufstieg"><strong>Ja</strong></template><template v-else>Nein</template></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="customerData mt-4" v-if="customer.custom_fields && customer.custom_fields.language">
        <h4>Sprachkenntnisse</h4>
        <div class="table-responsive">
          <table class="w100">

            <tr v-for="lang in customer.custom_fields.language.other">
              <td class="align-top d-none d-lg-block ">{{ lang.language }}</td>
              <td class="align-top">{{lang.level}}</td>
            </tr>
          </table>
        </div>
      </div>
<!--      <router-link :to="{name:'profile'}" class="btn btn-primary mb-4">Profil bearbeiten</router-link>-->
    </div>
    <div class="col-lg-6 col-sm-12"><h4>Punktzahlen</h4>
      <div class="counterData mb-3" v-if="counters && options">
        <div class="inline">
          <table class="table">
            <tr>
              <td style="padding:0; font-weight: bold" class="align-top">Titel</td>
              <td style="padding:0; font-weight: bold" class="align-top">Aktuelle Punkte</td>
              <td style="padding:0; font-weight: bold" class="align-top">Alte Punkte</td>
            </tr>
          </table>
        </div>
        <div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.vereinstaetigkeit.infos.title}}</td>
              <td class="align-top"><template v-if="counters.vereinstaetigkeit">{{counters.vereinstaetigkeit.groupSum}}</template><template v-else>0</template></td>
              <td class="align-top"><span class="oldValues"><template  v-if="counters.vereinstaetigkeit && counters.vereinstaetigkeit.oldPoints">{{counters.vereinstaetigkeit.oldPoints}}</template><template v-else>0</template> </span></td>
            </tr>
          </table>
        </div>
        <div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.verbandstaetigkeit.infos.title}}</td>
              <td class="align-top"> <template v-if="counters.verbandstaetigkeit">{{counters.verbandstaetigkeit.groupSum}}</template><template v-else>0</template></td>
              <td class="align-top"><span class="oldValues"><template  v-if="counters.verbandstaetigkeit && counters.verbandstaetigkeit.oldPoints">{{counters.verbandstaetigkeit.oldPoints}}</template><template v-else>0</template> </span></td>

            </tr>
          </table>
        </div>
        <div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.internationaltrainer.infos.title}}</td>
              <td class="align-top"><template v-if="counters.internationaltrainer">{{counters.internationaltrainer.groupSum}}</template></td>
              <td class="align-top"><span class="oldValues"><template  v-if="counters.internationaltrainer && counters.internationaltrainer.oldPoints">{{counters.internationaltrainer.oldPoints}}</template><template v-else>0</template> </span></td>

            </tr>
          </table>
        </div>
        <hr>
        <div class="inline" v-if="counters.vereinsspieler">
          <table class="table">
            <tr>
              <td class="align-top">Spieler*in Erfahrung: nationale Ebene</td>
              <td class="align-top"> {{counters.vereinsspieler.groupSum + counters.nationalspieler.groupSum}}</td>
              <td class="align-top"><span class="oldValues"><template  v-if="counters.vereinsspieler && counters.vereinsspieler.oldPoints">{{counters.vereinsspieler.oldPoints}}</template><template v-else>0</template> </span></td>

            </tr>
          </table>
        </div>
        <div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.internationalspieler.infos.title}}</td>
              <td class="align-top"><template v-if="counters.internationalspieler">{{counters.internationalspieler.groupSum}}</template></td>
              <td class="align-top"><span class="oldValues"><template  v-if="counters.internationalspieler && counters.internationalspieler.oldPoints">{{counters.internationalspieler.oldPoints}}</template><template v-else>0</template> </span></td>

            </tr>
          </table>
        </div>
        <div class="inline"  v-if="counters.groupSums !== counters.complete">
          <table class="table">
            <tr>
              <td class="align-top">Nicht anrechenbare Spieler*in Erfahrung (gemäß AO)</td>
              <td class="align-top">{{counters.diffSum}}</td>
              <td class="align-top"><span class="oldValues"><template>0</template></span></td>

            </tr>
          </table>
        </div>
        <hr>
        <div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.ausbildung.infos.title}}</td>
              <td class="align-top"> <template v-if="counters.ausbildung">{{counters.ausbildung.groupSum}}</template><template v-else>0</template></td>
              <td class="align-top"><span class="oldValues"><template  v-if="counters.ausbildung && counters.ausbildung.oldPoints">{{counters.ausbildung.oldPoints}}</template><template v-else>0</template> </span></td>

            </tr>
          </table>
        </div>
        <div class="inline" v-if="options.trainerausbildung">
          <table class="table">
            <tr>
              <td class="align-top">{{options.trainerausbildung.infos.title}}</td>
              <td class="align-top"> <template v-if="counters.trainerausbildung">{{counters.trainerausbildung.groupSum}}</template><template v-else>0</template></td>
              <td class="align-top"><span class="oldValues"><template  v-if="counters.trainerausbildung && counters.trainerausbildung.oldPoints">{{counters.trainerausbildung.oldPoints}}</template><template v-else>0</template> </span></td>

            </tr>
          </table>
        </div><div class="inline">
          <table class="table">
            <tr>
              <td class="align-top">{{options.weiterbildung.infos.title}}</td>
              <td class="align-top"> <template v-if="counters.weiterbildung">{{counters.weiterbildung.points}}</template><template v-else>0</template></td>
              <td class="align-top"><span class="oldValues"><template  v-if="counters.weiterbildung && counters.weiterbildung.oldPoints">{{counters.weiterbildung.oldPoints}}</template><template v-else>0</template> </span></td>

            </tr>
          </table>
        </div>
        <hr>
        <div class="complete">
          <table class="table">
            <tr>
              <th class="align-top">Gesamtpunktzahl</th>
              <td class="align-top"><strong style="padding: 0">{{counters.complete}}</strong></td>

              <td class="align-top"> <strong style="padding: 0">{{counters.oldComplete}}</strong></td>
            </tr>
          </table>

        </div>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
export default {

  name: 'DFBProfile',
  props: {

    customer: {},
    counters: {},
    options: {},

  },
  data() {
    return {
      complete: 0
    }
  },
  emits: ['refreshActiveRequest'],
  created() {


    let a;
    let c;
    for (a in this.counters) {
      c = this.counters[a];
      this.complete += c.points;
    }

  },
  methods: {
    deleteAvatar() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};


      axios.put(this.$store.getters.getApiUrl + 'customer/' + this.customer.id, {
        profile_image: '',
        profile_mimetype: ''
      }, {headers}).then(function (response) {
        let shortReport = response.data.result;
        if (shortReport.success === true) {
         this.$emit('refreshActiveRequest');

          this.$store.dispatch("toastSuccess", "Avatar gelöscht");
        } else {
          this.$store.dispatch("toastError", "Avatar konnte nicht gelöscht werden");
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
  }

}
</script>
<style lang="scss" scoped>
  th, td {
    border-bottom-color: red;
  }
  .containerInline {
    padding: 24px;
  }
  .inline table.table {
    margin-bottom: 0px;
  }
  .inline table {
    width: 100%;
  }
  .complete table td, .inline table td {
    text-align: right;
    width: 24%;
    &:first-child {
      width: 52%;
      text-align: left;
    }
    &:last-child {
      color: #ccc;
    }
  }
  .profileImage{

    max-height: 200px;
    max-width: 100%;

  }

  table.profileData{
    float: left;
    max-width: 100%;
    width: 400px;
  }
</style>
