<template  :key="project.id">
  <div class="projectWrap"
       :class="(projectActive === project.id) ? 'active': ''">
    <div class="d-flex">
      <div class="lineitem l-title"   @click="toggleProject(project.id)">
        {{ project.title }}
      </div>
      <div class="lineitem l-projectstate">
        <template v-if="project.state === 'deleted'">
          <span class="badge w-100 bg-danger" v-if="project.state" :class="'state_'+project.state">{{$t('project.state.'+ project.state) }}</span>
        </template>
        <template v-else-if="project.state === 'prepare'">
          <span class="badge w-100 bg-warning" v-if="project.state" :class="'state_'+project.state">{{$t('project.state.'+ project.state) }}</span>
        </template>
        <template v-else-if="project.state === 'order'">
          <span class="badge w-100 bg-success" v-if="project.state" :class="'state_'+project.state">{{$t('project.state.'+ project.state) }}</span>
        </template>
        <template v-else>
          <span class="badge w-100 bg-primary" v-if="project.state" :class="'state_'+project.state">{{$t('project.state.'+ project.state) }}</span>
          <span v-else>-</span>
        </template>



      </div>
      <div class="lineitem l-avatar">

        <img style="max-width: 40px"
             :src="$filters.employeeIcon(getEmployees, project.employee_id, 'id')"
             class="sketchAvatar">
      </div>
      <div class="lineitem l-date" :tooltip="'erstellt'" :position="'left'"><i
        class="fa-duotone fa-calendar-circle-plus"></i> {{ $filters.formatDate(project.crm_created_date) }}
      </div>
      <div class="lineitem l-date" :tooltip="'bearbeitet'" :position="'left'"><i
        class="fa-duotone fa-calendar-circle-user"></i> {{ $filters.formatDate(project.crm_modified_date) }}
      </div>
      <div class="lineitem l-action ml-auto">
        <div class="btn-group">
          <button class="btn btn-outline-primary btn-sm" @click="setProject(project)"
                  :tooltip="'Projekt bearbeiten'" :position="'left'">
            <i class="fa-duotone fa-wrench"></i>
          </button>
          <button class="btn btn-outline-danger btn-sm" @click="deleteProject(project)"
                  :tooltip="'Projekt löschen'" :position="'left'">
            <i class="fa-duotone fa-trash-can"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="projectDetails" v-if="projectActive === project.id">
    <Details :project="project" />
  </div>

</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import Edit from "@/routing/core/Projects/Edit.vue";
import Details from "@/routing/core/Projects/Details.vue";

export default {
  name: "List",
  emits: ['refresh-project-list', 'new-project', 'project-active'],
  components: {Details, Edit},
  props: {
    project: {
      type: Object,
      required: true
    },
    projectActive: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      newProjectItem: {

      },
      accessData: [],

    }
  },
  computed: {
    ...mapGetters(["customerActive", "getEmployees",  "modalsList"])
  },
  methods:{
    setProject(project) {
      this.$emit('new-project', project);
      this.$store.commit('openModal', 'project');
    },
    deleteProject(project) {
      this.$confirm('Soll der Eintrag wirklich gelöscht werden?', 'Projekt löschen', 'warning', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        type: 'warning'
      }).then(() => {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "project/" + project.id, {headers}).then((r) => {
          this.$emit('refresh-project-list');
        });
      });
    },
    toggleProject(id) {
      if (this.projectActive === id) {
        this.accessData = [];
        this.$emit('project-active', '');
      } else {
        this.getAccessData();
        this.$emit('project-active', id);
      }
    },
    getAccessData() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/accessData", {headers}).then((r) => {
        this.accessData = r.data.result.data;
      });
    },
  }
}
</script>

