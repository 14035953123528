<template>
  <div class="card-header card-header-inline">
    <template
      v-if="getConfigs && getConfigs.params_values && (taskActive && taskActive.state_id === getConfigs.params_values.issue_default_close)">
      <button class="checkCircleBtn btn-primary btn btn-sm" @click="uncheckTask(taskActive.id)"><i
        class="fa-duotone fa-check fa-xl"></i></button>
    </template>
    <template v-else>
      <button class="checkCircleBtn btn-outline-primary btn btn-sm" @click="checkTask(taskActive.id)"><i
        class="fa-duotone fa-check fa-xl"></i></button>
    </template>
    <h6 class="editLine">{{ taskActive.title }}</h6>
    <div class="btn-group float-end">
      <UploadComponent :customer-id="taskActive.crm_customer_id" :task-id="taskActive.id" :compact="true"/>
    </div>
  </div>
  <div class="card-body">
    <ul class="fileList list-group">
      <template v-if="attachments" v-for="at in attachments">
        <li class="list-group-item">
          <div class="btn-group me-3">
            <template v-if="buttonLoading">
              <button class="btn btn-sm btn-outline-secondary" disabled><i class="fa-duotone fa-spinner-third fa-spin"></i>
              </button>
            </template>
            <template v-else>
            <button class="btn btn-sm btn-outline-secondary" @click="downloadFile(at.customer_id, at.id)"><span
              tooltip="Download">
        <i class="fa-duotone fa-paperclip"></i></span></button>
            </template>
            <button
              :disabled="at.contentType !=='application/pdf' && at.contentType !== 'image/jpeg' && at.contentType !== 'image/png' && at.contentType !== 'image/svg' && at.contentType !== 'image/jpg' && at.contentType !== 'image/webp'"
              class="btn btn-sm btn-outline-primary" @click="previewFile(at.customer_id, at.id)"><span
              tooltip="Vorschau">
        <i class="fa-duotone fa-eye"></i></span></button>
          </div>
          <small class="float-end"><button class="btn btn-sm btn-outline-secondary float-end me-2 ms-3" @click="$store.dispatch('copyToClipboard','{attach:'+at.id+'}')">{{at.id}}</button></small>
          <button class="btn btn-sm btn-outline-danger float-end me-2 ms-3" @click="deleteFile(at.id)">
          <span tooltip="Löschen">
          <i class="fa-trash-alt fa-duotone"></i></span></button>
          <small>{{ at.title }} - {{ $filters.formatDateTime(at.uploaded_on) }}</small>
        </li>
      </template>
    </ul>
  </div>
  <template>
    <Modal v-if="modalsList.previewFile">
      <div class="modal show" style="display: block;">
        <div class="modal-dialog modal-xl purpleView">
          <div class="modal-content" v-if="!loading">
            <template v-if="file">
              <div class="modal-header">
                {{ file.title }}
                <button type="button" class="btn-close" @click="closeFile()"></button>
              </div>
              <div class="modal-body">
                <template v-if="file.contentType ==='application/pdf'  ">
                  <vue-pdf-embed style="min-height: 80vh;" :source="'data:'+file.contentType+';base64,'+file.content" />
                </template>
                <template v-else>
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="btn-group">
                        <button @click="zoomIn" class="btn btn-outline-primary btn-sm mb-2"><i
                          class="fa-duotone fa-magnifying-glass-plus"></i></button>
                        <button @click="zoomOut" class="btn btn-outline-primary btn-sm mb-2"><i
                          class="fa-duotone fa-magnifying-glass-minus"></i></button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 text-center">
                      <p class="mb-1"><small><i class="fa-duotone fa-computer-mouse-scrollwheel color-purple"></i> Das
                        Mausrad kann zum Zoomen verwendet werden</small>
                      </p></div>
                  </div>
                  <div class="row" @wheel.prevent="handleWheel($event)">
                    <div class="col-12" style="overflow: hidden;">
                      <img :src="'data:'+file.contentType+';base64,'+file.content"
                           :style="{ transform: `scale(${zoomLevel})`, transformOrigin: transformOrigin }"
                           style="display: block; width: 100%; height: auto;">
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div class="modal-content" v-else>
            <div class="modal-header">
              <h5 class="modal-title">Datei wird geladen..</h5>
              <button type="button" class="btn-close" @click="closeFile()"></button>
            </div>
            <div class="modal-body">
              <preLoader type="inner" :loading="loading" height="300px" style="background: #fff;"/>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </template>
</template>
<script>
import axios from "axios";
import UploadComponent from "@/directives/Upload/UploadComponent.vue";
import {mapGetters} from "vuex";
import EditorHtml from "@/components/EditorHtml.vue";
import preLoader from "@/components/Loading.vue";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  name: "Attachments",
  emits: ["update", "checkTask", "uncheckTask"],
  props: {
    attachments: ""
  },
  components: {
    EditorHtml,
    UploadComponent,
    preLoader,
    VuePdfEmbed
  },
  computed: {
    ...mapGetters(["modalsList", "taskActive", "getConfigs"])
  },
  data() {
    return {
      file: '',
      loading: false,
      buttonLoading: false,
      zoomLevel: 1,
      transformOrigin: 'center center'
    };
  },
  methods: {
    previewFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      this.loading = true;
      this.$store.commit('openModal', 'previewFile');
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          this.file = response.data.result.data;
          this.loading = false;
          // show in modal
        })
        .catch((error) => {
          console.log(error);
        });
    },
    zoomIn() {
      this.zoomLevel += 0.1;
    },
    zoomOut() {
      if (this.zoomLevel > 0.8) this.zoomLevel -= 0.1;
    },
    handleWheel(event) {
      const target = event.target; // This should be your image or embed element
      const rect = target.getBoundingClientRect();
      // Calculate cursor position inside the element as a percentage
      const xPercent = ((event.clientX - rect.left) / rect.width) * 100;
      const yPercent = ((event.clientY - rect.top) / rect.height) * 100;
      // Update transformOrigin based on cursor position
      this.transformOrigin = `${xPercent}% ${yPercent}%`;
      // Zoom in or out
      if (event.deltaY < 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    checkTask() {
      this.$emit('checkTask');
    },
    uncheckTask(task) {
      this.$emit('uncheckTask');
    },
    closeFile() {
      this.file = '';
      this.$store.commit('closeModal', 'previewFile');
    },
    downloadFile(customerId, attachmentId) {
      this.buttonLoading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          let file = response.data.result.data;
          this.file = file;
          const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', file.title);
          window.document.body.appendChild(a);
          a.click();
          this.buttonLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.buttonLoading = false;
        });
    },
    deleteFile(attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.delete(this.$store.state.apiUrl + "attachment/" + attachmentId, {headers: headers})
        .then((response) => {
          // Reload Task
          this.$emit('update');
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>
